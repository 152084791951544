var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"ModalRedeemSuccess"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"fs-18 modal-body txt-grey-900"},[_c('div',[_vm._v(" Chúc mừng quý khách "),_c('strong',{staticStyle:{"color":"#10b981"}},[_vm._v("đã kích hoạt")]),_vm._v(" thành công "),_c('strong',[_vm._v("e-Voucher")]),_vm._v(" trị giá "),_c('span',{staticClass:"uppercase font-bold",staticStyle:{"color":"#10b981"}},[_vm._v(_vm._s(_vm.appUtils.numberFormat(_vm.voucherInfo.DiscountMoney,"VNĐ")))]),_vm._v(". Chuyên viên của HODO sẽ liên hệ với Quý khách để hoàn tất dịch vụ. ")]),_c('div',{staticClass:"mt-4 fs-18"},[_vm._v("Mọi thắc mắc cần được hỗ trợ, xin vui lòng liên hệ người phụ trách:")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex items-center justify-center modal-header"},[_c('h5',{staticClass:"modal-title text-center fs-24 font-bold uppercase"},[_vm._v("Đăng ký thành công")]),_c('button',{staticClass:"close btn cs-close absolute right-0 top-1/2 transform -translate-y-1/2",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Account Manager: "),_c('strong',[_vm._v("MS. Nguyễn Hồng Nhung")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Mobile: "),_c('a',{attrs:{"target":"_blank","href":"tel:+84977339188"}},[_vm._v("0977.339.188")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Hotline: "),_c('a',{attrs:{"target":"_blank","href":"tel:+84345900905"}},[_vm._v("0345900905")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Email: "),_c('a',{attrs:{"target":"_blank","href":"mailto:nhungnguyen@hodo.com.vn"}},[_vm._v("nhungnguyen@hodo.com.vn")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Website: "),_c('a',{attrs:{"href":"https://www.hodo.com.vn","target":"_blank"}},[_vm._v("https://www.hodo.com.vn")])])
}]

export { render, staticRenderFns }