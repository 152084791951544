<template>
  <div v-if="item_persons" class="table-responsive mt-1">
    <table class="table table-vcenter table-mobile-md card-table no--border table-patient-list">
      <thead>
      <tr>
        <th :class="classSortName" colspan="2" @click="sort(1, !sort_by)" class="sort">{{ $t("title.person.name") }}</th>
        <th :class="classSortGender" @click="sort(2, !sort_by)" class="sort">{{ $t("form.title.gender") }}</th>
        <th :class="classSortBday" @click="sort(3, !sort_by)" class="sort">{{ $t("form.title.bday") }}</th>
        <th>{{ $t("form.title.address") }}</th>
        <th>{{ $t("title.updated_at") }}</th>
        <!-- <th>{{ $t("title.shared_by") }}</th> -->
      </tr>
      </thead>
      <tbody v-if="item_persons" class="patient-list" id="doctor_patient_list" :style="{'max-height': parseInt(item_persons.count) < 10 ? parseInt(item_persons.count) * 60 + 'px' : '600px'  }">
      <tr v-for="item_person in item_persons.data" :key="item_person.id" >
        <td @click="goToDetail(item_person.person)"  style="cursor: pointer;">
          <span class="avatar">
            <span v-if="item_person.person.avatar" class="avatar" :style="'background-image: url(' + getImageURL(item_person.person.avatar) + ')'"></span>
          <span v-if="!item_person.person.avatar"
          class="avatar">{{ item_person.person.name ? item_person.person.name.charAt(0) : '' }}</span>
          </span>
        </td>
        <td :data-label="$t('title.person.name')" @click="goToDetail(item_person.person)"  style="cursor: pointer;">
          <div class="lh-sm py-1 align-items-center">
              {{ item_person.person.name }}<br>
            <div class="text-muted text-h5">{{ item_person.person.phone }}</div>
          </div>
        </td>

        <td :data-label="$t('form.title.gender')">
          {{ item_person.person.gender === 1 ? $t("form.gender.male") : $t("form.gender.female") }}
        </td>

        <td :data-label="$t('form.title.bday')">
          <div class="text-h5"><a href="javascript:;" class="text-reset">{{ formatDate(item_person.person.birthday)
            }}</a></div>
        </td>

        <td :data-label="$t('form.title.address')">
          <div>{{ item_person.person.address }}</div>
        </td>

        <td :data-label="$t('title.updated_at')">
          {{ formatDateAgo(item_person.person.updated_at) }}
        </td>
        <!-- <td :data-label="$t('title.shared_by')">
          {{ item_person.clinic_id && item_person.clinic_id == clinicId ? $t('title.this_clinic')[$orgModel] : $t('title.patient')
          }}
        </td> -->
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'DoctorPersonTable',
    props: {
      item_persons: Object,
      isClinic: Boolean
    },
    mounted () {
      const listElm = document.querySelector('#doctor_patient_list')
      listElm.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          this.loadMorePersons()
        }
      })
    },
    data () {
      return {
        sort_by: false,
        classSortName: 'sort-both',
        classSortGender: 'sort-both',
        classSortBday: 'sort-both'
      }
    },
    methods: {
      async onShowModalScan (id) {
        this.$emit('onShowModalScan', id)
      },
      async onRemovePerson (id) {
        this.$emit('onRemovePerson', id)
      },
      async onShowModalEdit (id) {
        this.$emit('onShowModalEdit', id)
      },
      formatDateAgo (date) {
        return window.moment(date, 'YYYY-MM-DD hh:mm:ss').fromNow()
      },
      formatDate (date) {
        return window.moment(date).format('DD/MM/YYYY')
      },
      goToDetail (item_person) {
        let self = this
        return self.$router.push(`/doctor/persons/` + item_person.id)
      },
      loadMorePersons () {
        if (this.item_persons.count < this.item_persons.total) {
          this.$emit('getMorePersons', this.item_persons.count + 10)
        }
      },
      sort (sort, by) {
        let self = this
        let params = {
          sort_by: by ? 'asc' : 'desc'
        }
        if (sort === 1) {
          params.sort = 'name'
          self.classSortName = by ? 'sort-up' : 'sort-down'
          self.classSortGender = 'sort-both'
          self.classSortBday = 'sort-both'
        } else if (sort === 2) {
          params.sort = 'gender'
          self.classSortGender = by ? 'sort-up' : 'sort-down'
          self.classSortName = 'sort-both'
          self.classSortBday = 'sort-both'
        } else if (sort === 3) {
          params.sort = 'birthday'
          self.classSortBday = by ? 'sort-up' : 'sort-down'
          self.classSortGender = 'sort-both'
          self.classSortName = 'sort-both'
        }
        self.sort_by = by
        self.params_sort = params
        self.$emit('sortPersons', params)
      }
    }
  }
</script>
