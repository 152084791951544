<template>
  <div>
    <div class="modal fade" id="ModalRedeemSuccess">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="relative flex items-center justify-center modal-header">
            <h5 class="modal-title text-center fs-24 font-bold uppercase">Đăng ký thành công</h5>
            <button
              type="button"
              class="close btn cs-close absolute right-0 top-1/2 transform -translate-y-1/2"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="fs-18 modal-body txt-grey-900">
            <div>
              Chúc mừng quý khách
              <strong style="color:#10b981">đã kích hoạt</strong> thành công
              <strong>e-Voucher</strong> trị giá
              <span
                class="uppercase font-bold"
                style="color:#10b981"
              >{{ appUtils.numberFormat(voucherInfo.DiscountMoney,"VNĐ") }}</span>. Chuyên viên của HODO sẽ liên hệ với Quý khách để hoàn tất dịch vụ.
            </div>
            <div
              class="mt-4 fs-18"
            >Mọi thắc mắc cần được hỗ trợ, xin vui lòng liên hệ người phụ trách:</div>
            <div>
              Account Manager:
              <strong>MS. Nguyễn Hồng Nhung</strong>
            </div>
            <div>
              Mobile:
              <a target="_blank" href="tel:+84977339188">0977.339.188</a>
            </div>
            <div>
              Hotline:
              <a target="_blank" href="tel:+84345900905">0345900905</a>
            </div>
            <div>
              Email:
              <a target="_blank" href="mailto:nhungnguyen@hodo.com.vn">nhungnguyen@hodo.com.vn</a>
            </div>
            <div>
              Website:
              <a href="https://www.hodo.com.vn" target="_blank">https://www.hodo.com.vn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import appUtils from '../../utils/appUtils'

export default {
  name: 'ModalRedeem',
  props: {
    voucherInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      appUtils
    }
  },
  computed: {},
  watch: {},
  created () {},
  methods: {
    handleShowModal (show) {
      window.$('#ModalRedeemSuccess').modal(show ? 'show' : 'hide')
    }
  }
}
</script>
    <style scoped lang="scss">
.cs-close {
  background-color: transparent;
  font-size: 30px;
  border: none;
  color: white;
}

.cs-bg-pro {
  background: #370fd9;
  background: linear-gradient(to right, #370fd9 0%, #e3563b 100%);
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  color: white;
}

.cs-standard {
  color: rgb(48, 96, 241);
}

.cs-plus {
  color: rgb(255, 199, 68);
}

.cs-bg-plus {
  background-color: rgb(255, 199, 68);
  color: white;
}
</style>
    