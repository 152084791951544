<template>
  <div class="telemedicine-management pr-3">
    <div>
      <div class="row" style="padding: 0px 0 152px 0;">
        <form>
          <div class="form-group mb-3">
            <label class="robo-20-500" for="txt-email">{{$t('personal_settings.lbl_email_login')}}</label>
            <input
              type="text"
              readonly="true"
              class="form-control mt-2"
              id="txt-email"
              :value="doctor.username ? doctor.username : ''"
            />
          </div>
          <div class="form-group mb-3" v-if="has_pass">
            <label class="d-flex align-items-center justify-content-between" for="txt-old-password">
              <div class="robo-20-500">
                {{$t('personal_settings.lbl_old_password')}}
                <span class="text-red">*</span>
              </div>
              <div class="robo-14-400 txt-pri">
                <a
                  href="javascript:void(0)"
                  @click="onShowForgetPassword()"
                >{{$t('personal_settings.lbl_forgot_pwd')}}</a>
              </div>
            </label>
            <div class="position-relative">
              <input
                :type="show_oldpw ? 'password' : 'text'"
                v-model="form.oldpassword"
                class="form-control mt-2"
                id="txt-old-password"
                :placeholder="$t('personal_settings.lbl_pwd_50_require')"
                onfocus="this.removeAttribute('readonly');"
                readonly
                style="margin-right: 15px;"
                :class="error.error_oldpassword ? 'error-form-input-group' : !show_oldpw && !error.repass ? 'show-pw-form-input' : ''"
              />
              <span
                @click="show_oldpw  = !show_oldpw "
                class="position-absolute border-0 cursor-pointer mr-2"
                style="top: 19px; right: 10px;"
              >
                <i
                  :class="!show_oldpw ? 'font-size-20 text-muted fa fa-eye' : 'font-size-20 text-muted fa fa-eye-slash'"
                ></i>
              </span>
            </div>
            <span
              v-show="error.error_oldpassword"
              class="text-red robo-14-400"
            >{{error.error_oldpassword}}</span>
          </div>
          <div class="form-group mb-3">
            <label
              class="robo-20-500 required"
              for="txt-new-password"
            >{{$t('personal_settings.lbl_new_pwd')}}</label>
            <div class="position-relative">
              <input
                :type="show_newpw ? 'password' : 'text'"
                v-model="form.newpassword"
                class="form-control mt-2"
                id="txt-new-password"
                :placeholder="$t('personal_settings.lbl_pwd_50_require')"
                onfocus="this.removeAttribute('readonly');"
                readonly
                :class="error.error_newpassword ? 'error-form-input-group' : !show_newpw && !error.repass ? 'show-pw-form-input' : ''"
              />
              <span
                @click="show_newpw  = !show_newpw "
                class="position-absolute border-0 cursor-pointer mr-2"
                style="top: 19px; right: 10px;"
              >
                <i
                  :class="!show_newpw ? 'font-size-20 text-muted fa fa-eye' : 'font-size-20 text-muted fa fa-eye-slash text-white'"
                ></i>
              </span>
            </div>
            <span
              v-show="error.error_newpassword"
              class="text-red robo-14-400"
            >{{error.error_newpassword}}</span>
          </div>
          <div class="form-group mb-3">
            <label
              class="robo-20-500 required"
              for="txt-password-agian"
            >{{$t('personal_settings.lbl_new_pwd_sh')}}</label>
            <div class="position-relative">
              <input
                :type="show_repw ? 'password' : 'text'"
                v-model="form.passwordagian"
                class="form-control mt-2"
                id="txt-password-agian"
                :placeholder="$t('personal_settings.lbl_pwd_50_require')"
                onfocus="this.removeAttribute('readonly');"
                readonly
                :class="error.error_passwordagain ? 'error-form-input-group' : !show_repw && !error.repass ? 'show-pw-form-input' : ''"
              />
              <span
                @click="show_repw  = !show_repw "
                class="position-absolute border-0 cursor-pointer mr-2"
                style="top: 19px; right: 10px;"
              >
                <i
                  :class="!show_repw ? 'font-size-20 text-muted fa fa-eye' : 'font-size-20 text-muted fa fa-eye-slash text-white'"
                ></i>
              </span>
            </div>
            <span
              v-show="error.error_passwordagain"
              class="text-red robo-14-400"
            >{{error.error_passwordagain}}</span>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button
              class="btn bg-pri bd-pri text-white btn-update"
              @click.prevent="changePassword()"
            >{{$t('personal_settings.btn_update')}}</button>
          </div>
        </form>
      </div>
    </div>
    <ForgetPassword :email="doctor.username" :readonly="true"></ForgetPassword>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import ForgetPassword from './ForgetPassword.vue'
import { textValidatePasswordAgain } from '../../constants/text'
export default {
  name: 'ChangePassword',
  components: { ForgetPassword },
  data () {
    return {
      error: {
        error_oldpassword: '',
        error_passwordagain: '',
        error_newpassword: ''
      },
      form: {
        oldpassword: '',
        newpassword: '',
        passwordagian: ''
      },
      doctor: {},
      show_oldpw: true,
      show_newpw: true,
      show_repw: true,
      is_loaded: true,
      is_show: false,
      has_pass: false
    }
  },
  mounted () {
    this.checkHasPw()
    this.doctor = appUtils.getLocalUser()
  },
  methods: {
    checkValidate () {
      let self = this
      if (this.has_pass) {
        if (!self.checkPass(self.form.oldpassword)) {
          self.error.error_oldpassword = this.$t(
            'personal_settings.lbl_pwd_50_require'
          )
          return false
        }
      }
      if (!self.checkPass(self.form.newpassword)) {
        self.error.error_oldpassword = ''
        self.error.error_newpassword = this.$t(
          'personal_settings.lbl_pwd_50_require'
        )
        return false
      }
      if (self.form.passwordagian !== self.form.newpassword) {
        self.error.error_newpassword = ''
        self.error.error_passwordagain = textValidatePasswordAgain
        return false
      }
      return true
    },
    checkPass (str) {
      if (str.length >= 6) {
        var result = str.match(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?_-]{6,50}$/
        )
          ? str
            .match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?_-]{6,50}$/)
            .toString()
          : ''
        if (result === str) {
          return true
        }
        return false
      }
      return false
    },
    async changePassword () {
      let self = this
      if (self.checkValidate()) {
        self.error.error_oldpassword = ''
        self.error.error_newpassword = ''
        self.error.error_passwordagain = ''
        var param = {
          password: '',
          password_confirmation: ''
        }
        if (self.form.oldpassword) param.old_password = self.form.oldpassword
        param.password = self.form.newpassword
        param.password_confirmation = self.form.passwordagian
        try {
          let rep = await self.$rf
            .getRequest('AuthRequest')
            .postPasswordAgain(param)
            .catch(() => {})
          if (rep.status === 'success') {
            self.$swal.fire({
              html: '<p>Cập nhật mật khẩu thành công!</p>',
              icon: 'success'
            })
            // self.onLogin()
            self.$router.push({ path: '/' })
          }
        } catch (e) {
          console.log(e)
        } finally {
        }
      }
    },
    onLogin () {
      this.$router.push('/login').catch(() => {})
    },
    onShowForgetPassword () {
      window.$('#modal__forgot').modal('show')
    },
    async checkHasPw () {
      let self = this
      await this.$rf
        .getRequest('AuthRequest')
        .checkHasPw()
        .then(r => {
          self.has_pass = r.data
        })
    }
  }
}
</script>
<style scoped>
.form-control {
  height: 60px;
}
@media (min-width: 1607px) {
  .btn-update {
    width: 364px;
    height: 60px;
  }
}
.icon-item {
  background-image: url("../../../public/assets/images/icon/next.svg");
  background-repeat: no-repeat;
  background-size: 14px 7px;
  background-origin: center;
  background-position: center;
  height: 7px;
  width: 14px;
}
</style>