<template>
  <div style="min-width: 1000px">
    <h2
      class="uppercase text-primary fs-24 font-bold text-center my-10"
    >Chính sách giá bán HODO PRO PLATFORM</h2>

    <el-table
      border
      highlight-current-row
      highlight-selection-row
      :expand-row-keys="[0]"
      row-key="index"
      class="cs-table"
      ref="tableContainer"
      cell-class-name=" text-black fs-16"
      :data="tableData"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div
            id="sub-benefit"
            class="ml-10 flex flex-col gap-2 pl-4"
            v-if="props.row.sub_benefit && props.row.sub_benefit.length"
          >
            <div v-for="(item,index) in props.row.sub_benefit" :key="index">
              <div>
                <div :id="`heading-${index}`">
                  <h5 class="mb-0">
                    <div
                      class="fs-16 flex gap-1 items-center font-normal"
                      data-toggle="collapse"
                      :data-target="`#collapse-${index}`"
                      aria-expanded="false"
                      :aria-controls="`collapse-${index}`"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="transform -rotate-90"
                      >
                        <path
                          d="M11.9999 17C11.7447 16.9987 11.4929 16.9406 11.2628 16.8299C11.0328 16.7192 10.8302 16.5587 10.6699 16.36L6.45995 11.26C6.21394 10.953 6.05913 10.583 6.01316 10.1923C5.9672 9.80153 6.03191 9.40574 6.19995 9.05C6.33623 8.74083 6.55862 8.47741 6.84057 8.29122C7.12251 8.10503 7.45209 8.00393 7.78995 8H16.2099C16.5478 8.00393 16.8774 8.10503 17.1593 8.29122C17.4413 8.47741 17.6637 8.74083 17.7999 9.05C17.968 9.40574 18.0327 9.80153 17.9867 10.1923C17.9408 10.583 17.786 10.953 17.5399 11.26L13.3299 16.36C13.1696 16.5587 12.9671 16.7192 12.7371 16.8299C12.507 16.9406 12.2552 16.9987 11.9999 17Z"
                          fill="black"
                        />
                      </svg>

                      <label>{{ item.name }}</label>
                    </div>
                  </h5>
                </div>

                <div
                  :ref="`collapse-${index}`"
                  :id="`collapse-${index}`"
                  class="collapse"
                  :aria-labelledby="`heading-${index}`"
                  data-parent="#sub-benefit"
                >
                  <div class="card-body">
                    <ul class="fs-16 font-normal" v-if="item.description&&item.description.length">
                      <li v-for="(desc,index) in item.description" :key="index">{{ desc }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-10 pl-4" v-else>
            <ul
              class="fs-16 font-normal"
              v-if="props.row.description&&props.row.description.length"
            >
              <li v-for="(item,index) in props.row.description" :key="index">{{ item }}</li>
            </ul>
          </div>
          <!-- <ul>
            <li>State: {{ props.row.state }}</li>
            <li>City: {{ props.row.city }}</li>
            <li>Address: {{ props.row.address }}</li>
            <li>Zip: {{ props.row.zip }}</li>
          </ul>-->
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name">
        <template slot="header">
          <label class="fs-18 font-normal">Tính năng</label>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="standard">
        <template slot="header">
          <div class="flex gap-1 flex-col items-center justify-center">
            <div class="fs-24 font-bold cs-standard" style="color:rgb(48, 96, 241)">Standard</div>
            <div class="fs-16 font-normal" style="color:#10b981">
              <span
                class="uppercase"
                style="color:#10b981"
              >{{`${monthPrice[0]&&monthPrice[0].price}`}}</span>
              /Tháng
            </div>
          </div>
        </template>
        <template slot-scope="props">
          <div v-if=" props.row.standard==='ok'">
            <i class="el-icon-circle-check fs-24 text-green"></i>
          </div>
          <span v-else>{{ props.row.standard }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="plus">
        <template slot="header">
          <div class="flex gap-1 flex-col items-center justify-center">
            <div class="fs-24 font-bold cs-plus" style="color:rgb(255, 199, 68)">Plus</div>
            <div class="fs-16 font-normal" style="color:#10b981">
              <span
                class="uppercase"
                style="color:#10b981"
              >{{`${monthPrice[1]&&monthPrice[1].price}`}}</span>
              /Tháng
            </div>
          </div>
        </template>
        <template slot-scope="props">
          <div v-if=" props.row.plus==='ok'">
            <i class="el-icon-circle-check fs-24 text-green"></i>
          </div>
          <span v-else>{{ props.row.plus }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="pro">
        <template slot="header">
          <div class="flex gap-1 flex-col items-center justify-center">
            <div class="fs-24 font-bold cs-pro" style="color:rgb(78, 235, 238)">Pro</div>
            <div class="fs-16 font-normal" style="color:#10b981">Liên hệ</div>
          </div>
        </template>
        <template slot-scope="props">
          <div v-if="props.row.pro==='ok'">
            <i class="el-icon-circle-check fs-24 text-green"></i>
          </div>
          <span v-else>{{ props.row.pro }}</span>
        </template>
      </el-table-column>
      <template slot="append">
        <el-table ref="elTable" class="cs-table-footer">
          <template slot="empty">
            <span>{{ `` }}</span>
          </template>
          <el-table-column type="expand"></el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="fs-18 font-bold flex items-center justify-center h-full">Chi phí/Năm</div>
            </template>
          </el-table-column>
          <el-table-column prop="standard">
            <template slot="header">
              <div class="flex gap-2 h-full flex-col items-center justify-center">
                <div class="fs-18 font-bold uppercase">{{ yearPrice[0]&&yearPrice[0].price }}</div>
                <button
                  @click="handleSelectPackage(HODO_PACKAGE.standard)"
                  class="btn cs-bg-standard"
                  :class="handleCheckActive(HODO_PACKAGE.standard.value)&&'opacity-100'"
                >{{handleCheckActive(HODO_PACKAGE.standard.value)?"Đã kích hoạt":isActive?'Liên hệ':"Mua"}}</button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="plus">
            <template slot="header">
              <div class="flex h-full gap-2 flex-col items-center justify-center">
                <div class="fs-18 font-bold uppercase">{{ yearPrice[1]&&yearPrice[1].price }}</div>
                <button
                  @click="handleSelectPackage(HODO_PACKAGE.plus)"
                  class="btn cs-bg-plus"
                  :class="handleCheckActive(HODO_PACKAGE.plus.value)&&'opacity-100'"
                >{{handleCheckActive(HODO_PACKAGE.plus.value)?"Đã kích hoạt":isActive?'Liên hệ':"Mua"}}</button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="pro">
            <template slot="header">
              <div class="flex h-full gap-2 flex-col items-center justify-center">
                <div class="fs-18 font-bold invisible">0</div>
                <button
                  @click="handleSelectPackage(HODO_PACKAGE.pro)"
                  class="btn cs-bg-pro"
                  :class="handleCheckActive(HODO_PACKAGE.pro.value)&&'opacity-100'"
                >{{handleCheckActive(HODO_PACKAGE.pro.value)?"Đã kích hoạt":"Liên hệ"}}</button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table>

    <ModalRedeem
      @onRefresh="handleRefresh"
      :isActive="isActive"
      :selectedPackage="selectedPackageInfo"
    />
    <ModalRedeemSuccess :voucherInfo="voucherInfo" />
  </div>
</template>

<script>
import ModalRedeem from './ModalRedeem.vue'
import { HODO_PACKAGE } from '@/utils/constants'
import ModalRedeemSuccess from './ModalRedeemSuccess.vue'

export default {
  name: 'AccountSetting',
  components: { ModalRedeem, ModalRedeemSuccess },
  data () {
    return {
      tableData: [],
      selectedPackageInfo: {},
      collapseIndex: {
        0: false,
        1: false,
        2: false
      },
      HODO_PACKAGE,
      packageData: {},
      standardDiscount: {},
      plusDiscount: {},
      activePackage: {},
      monthPrice: [],
      yearPrice: [],
      voucherInfo: {}
    }
  },
  created () {
    this.handleRefresh()
    this.handleGetVoucherInfo()
  },

  computed: {
    isActive () {
      return this.activePackage?.organization_transaction?.payment_status === 1
    },
    orgId () {
      const orgInfo = this.$user?.members?.[0]

      return (
        this.$globalClinic?.org_id || this.$globalOrg?.id || orgInfo?.org_id
      )
    }
  },
  methods: {
    handleShowModal (show) {
      window.$('#ModalRedeem').modal(show ? 'show' : 'hide')
    },
    handleSelectPackage (selectedPackage) {
      const packageInfo = this.packageData?.service_groups?.find(
        item => item.id === selectedPackage.value
      )
      this.selectedPackageInfo = packageInfo
      if (this.handleCheckActive(packageInfo.id)) {
        window.$('#ModalRedeemSuccess').modal('show')
      } else {
        this.handleShowModal(true)
      }
    },
    async handleGetBenefits () {
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getHodoBenefits()
        this.tableData = (response.data?.benefits || []).map((item, index) => ({
          ...item,
          index
        }))

        this.monthPrice = response.data?.month_price || []
        this.yearPrice = response.data?.year_price || []

        if (this.$refs.tableContainer) {
          this.$refs.tableContainer.$refs.tableHeader.$forceUpdate()
        }

        if (this.$refs.elTable) {
          this.$refs.elTable.$refs.tableHeader.$forceUpdate()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetPackageInfo () {
      try {
        const params = {
          org_id: this.orgId
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getPackageInfo(params)

        this.packageData = response.data
        this.activePackage = response.data?.org_hodo?.find(
          item => item?.organization_transaction?.id
        )
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetVoucherInfo () {
      try {
        const params = {
          org_id: this.orgId
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getVoucherInfo(params)

        this.voucherInfo = response.data || {}

        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
    handleCheckActive (packageId) {
      const isActive =
        this.activePackage?.ServiceGroup?.id === packageId &&
        this.activePackage?.organization_transaction?.payment_status === 1

      if (this.$refs.elTable) {
        this.$refs.elTable.$refs.tableHeader.$forceUpdate()
      }
      return isActive
    },
    handleRefresh (isSuccess) {
      this.handleGetBenefits()
      this.handleGetPackageInfo()

      if (isSuccess) {
        window.$('#ModalRedeemSuccess').modal('show')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-table * {
  color: black;
}

.cs-pro {
  // background: #ffffff;
  // background: radial-gradient(
  //   circle farthest-corner at center center,
  //   #ffffff 0%,
  //   #000000 27%,
  //   #000000 48%,
  //   #ffffff 100%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  background: #370fd9;
  background: linear-gradient(to right, #370fd9 0%, #e3563b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cs-bg-pro {
  background: rgb(55, 15, 217);
  background: linear-gradient(
    66deg,
    rgba(55, 15, 217, 1) 0%,
    rgba(223, 65, 34, 1) 100%
  );
  color: white;
  border: none;
  max-width: 120px;
  width: 100%;
  height: 40px;
  font-size: 18px;
}

.cs-bg-standard {
  background: rgb(48, 96, 241);

  color: white;
  border: none;
  max-width: 120px;
  width: 100%;
  height: 40px;
  font-size: 18px;
}

.cs-bg-plus {
  background: rgb(255, 199, 68);
  // background: linear-gradient(
  //   66deg,
  //   rgba(55, 15, 217, 1) 0%,
  //   rgba(223, 65, 34, 1) 100%
  // );
  color: white;
  border: none;
  max-width: 120px;
  width: 100%;
  height: 40px;
  font-size: 18px;
}

.cs-standard {
  // background: #b029ff;
  // background: linear-gradient(to right, #b029ff 0%, #b84630 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.cs-plus {
  // background: #250dff;
  // background: linear-gradient(to right, #250dff 0%, #ff73f6 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

::v-deep {
  .cs-table-footer {
    .el-table__empty-block {
      display: none !important;
    }

    .el-table__header {
      height: 120px;

      .cell {
        height: 100%;
      }
    }
  }
}

[aria-expanded="true"] {
  svg {
    transform: rotate(0);
  }
}
</style>