<template>
	<div>
		<div class="modal fade" id="modal__forgot" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalCenterTitle">{{$t('personal_settings.btl_forget_pwd')}}</h5>
					</div>
					<form @submit.prevent="onForgot">
						<div class="modal-body">
							<div class="form-group mb-4">
								<label class="robo-20-500 text-black required">Email</label>
								<input readonly="readonly" type="email" required class="form-control form-input-login" aria-describedby="emailHelp" v-model="email" placeholder="Hodo@905.vn">
							</div>
							<div class="mb-4">
								<vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="sitekey" :loadRecaptchaScript="true">
								</vue-recaptcha>
							</div>
							<div class="d-flex justify-content-between">
								<button type="button" class="btn-l btn--ghost" data-dismiss="modal">{{$t('personal_settings.btn_close')}}</button>
								<button type="submit" class="btn-l bg-pri bd-pri text-white" :disabled="loading || !verify_captcha">
									<span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
									{{$t('button.send')}}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha'
  export default {
    name: 'ForgetPassword',
    props: ['email', 'readonly'],
    components: { VueRecaptcha },
    mounted () {
    },
    computed: {},
    data () {
      return {
        sitekey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
        verify_captcha: false,
        form_forgot: {
          email: ''
        },
        loading: false
      }
    },
    methods: {
      onVerify (response) {
        this.verify_captcha = true
      },
      onExpired () {
        this.verify_captcha = false
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset()
      },
      async onForgot () {
        let self = this
        self.loading = true
        if (self.verify_captcha) {
          try {
            self.form_forgot.email = self.email ? self.email : ''
            let reps = await self.$rf.getRequest('AuthRequest').postForgotGo(self.form_forgot)
            if (reps.status === 'success') {
              self.$swal.fire({
                html: self.$t('personal_settings.lbl_noti_email_msg')[self.$orgModel],
                icon: 'success'
              })
            }
          } catch (e) {

          } finally {
            self.loading = false
            window.$('#modal__forgot').modal('hide')
            self.resetRecaptcha()
          }
        } else {
          self.$swal.fire({
            html: '<p>Vui lòng xác thực captcha!</p>',
            icon: 'error'
          })
        }
        self.loading = false
      }
    }
  }
</script>
<style scoped></style>