<template>
  <div>
    <div class="modal fade" id="ModalRedeem">
      <div
        v-loading="isLoading"
        class="modal-dialog modal-dialog-centered"
        :class="isPackagePro?'modal-md':'modal-lg'"
        role="document"
      >
        <div class="modal-content">
          <div
            class="relative flex items-center justify-center modal-header"
            :class="{
                'cs-bg-plus':selectedPackage.id===HODO_PACKAGE.plus.value,
                'cs-bg-pro':selectedPackage.id===HODO_PACKAGE.pro.value,
            }"
          >
            <h5 class="modal-title text-center fs-24 font-bold">{{selectedPackage.name}}</h5>
            <button
              @click="hideModal"
              type="button"
              class="close btn cs-close absolute right-0 top-1/2 transform -translate-y-1/2"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="fs-18 text-black" v-if="isPackagePro">
              <div>
                <h5 class="font-bold fs-20">Tính năng</h5>
                <ul
                  class="grid grid-cols-2 gap-x-4"
                  v-if="selectedPackage.organization &&selectedPackage.organization.length"
                >
                  <li
                    class="fs-18"
                    v-for="service in selectedPackage.organization"
                    :key="service.id"
                  >
                    <div>{{service.services&&service.services.service_title }}</div>
                    <div>{{ service.metadata?`(${service.metadata})`:'' }}</div>
                  </li>
                </ul>
              </div>
              <el-divider />
              <h5 class="font-bold fs-20">Thông tin liên hệ</h5>
              <div>
                Account Manager:
                <strong>MS. Nguyễn Hồng Nhung</strong>
              </div>
              <div>
                Mobile:
                <a target="_blank" href="tel:+84977339188">0977.339.188</a>
              </div>
              <div>
                Hotline:
                <a target="_blank" href="tel:+84345900905">0345900905</a>
              </div>
              <div>
                Email:
                <a
                  target="_blank"
                  href="mailto:nhungnguyen@hodo.com.vn"
                >nhungnguyen@hodo.com.vn</a>
              </div>
              <div>
                Website:
                <a href="https://www.hodo.com.vn" target="_blank">https://www.hodo.com.vn</a>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div>
                  <h5 class="font-bold fs-20">Tính năng</h5>
                  <ul
                    class="grid grid-cols-2 gap-x-4 gap-y-2"
                    v-if="selectedPackage.organization &&selectedPackage.organization.length"
                  >
                    <li
                      class="fs-18"
                      v-for="service in selectedPackage.organization"
                      :key="service.id"
                    >
                      <div
                        :class="service.services.sub_service&&service.services.sub_service.length>0?'flex flex-col':'flex'"
                      >
                        <div>{{service.services&&service.services.service_title }}</div>
                        <div class="fs-16 italic">{{ service.metadata?`(${service.metadata})`:'' }}</div>
                      </div>
                      <ul v-if="service.services.sub_service&&service.services.sub_service.length">
                        <li
                          class="fs-16"
                          v-for="subService in service.services.sub_service"
                          :key="subService.id"
                        >{{subService.service_title }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!-- <div class="flex gap-2 items-center">
                  <el-input class="radius-10" placeholder="Mã ưu đãi: XJMGOK" v-model="voucherCode"></el-input>
                  <button
                    @click="handleGetVoucherInfo"
                    :disabled="!voucherCode"
                    class="flex items-center justify-center border-0 focus:outline-none bg-orange-600 text-white rounded-md h-10 px-3 py-2 w-28 fs-16"
                  >Áp dụng</button>
                </div>-->
              </div>
              <el-divider class="my-2"></el-divider>
              <div v-if="!isActive">
                <div class="mb-3 flex items-end flex-col gap-2">
                  <div class="flex gap-2 items-center">
                    <strong class="fs-18">Chi phí:</strong>
                    <p
                      class="fs-18 font-bold mb-0 w-52 text-right"
                      style="color:#10b981"
                    >{{ selectedPackage.per_year_price?`${appUtils.numberFormat(selectedPackage.per_year_price,"VNĐ")}/Năm` :''}}</p>
                  </div>
                  <div v-if="voucherInfo.DiscountType" class="flex gap-2 items-center">
                    <strong class="fs-18">Giảm giá:</strong>
                    <p class="fs-18 font-bold mb-0 w-52 text-right">{{ handleGetDiscount() }}</p>
                  </div>

                  <div class="flex gap-2 items-center">
                    <strong class="fs-18">Tổng tiền:</strong>
                    <p
                      class="fs-18 font-bold mb-0 w-52 text-right"
                      style="color:#10b981"
                    >{{ `${appUtils.numberFormat(remainingPrice,"VNĐ")}/Năm`}}</p>
                  </div>
                </div>

                <div class="mt-6 text-sm font-medium flex justify-between gap-2">
                  <!-- <button
                data-dismiss="modal"
                aria-label="Close"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-12 px-3 py-2 w-full fs-18"
              >
                <div>Huỷ</div>
                  </button>-->

                  <button
                    @click="handleRedeem()"
                    :disabled="!voucherInfo.DiscountType"
                    class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-md h-10 px-3 py-2 fs-16 w-full"
                    :class="{
                    'cs-bg-plus':selectedPackage.id===HODO_PACKAGE.plus.value,
                    'cs-bg-pro':selectedPackage.id===HODO_PACKAGE.pro.value,
                    'opacity-60':!voucherInfo.DiscountType
                  }"
                  >
                    <div>Sử dụng Voucher</div>
                  </button>
                  <!-- <button
                  class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-md h-12 px-3 py-2 w-full fs-18"
                  :class="{
                    'cs-bg-plus':selectedPackage.id===HODO_PACKAGE.plus.value,
                    'cs-bg-pro':selectedPackage.id===HODO_PACKAGE.pro.value,
                  }"
                >
                  <div>Mua</div>
                  </button>-->
                </div>
              </div>
              <div class="fs-18" v-else>
                <h5 class="font-bold fs-20">Thông tin liên hệ</h5>
                <div>
                  Account Manager:
                  <strong>MS. Nguyễn Hồng Nhung</strong>
                </div>
                <div>
                  Mobile:
                  <a target="_blank" href="tel:+84977339188">0977.339.188</a>
                </div>
                <div>
                  Hotline:
                  <a target="_blank" href="tel:+84345900905">0345900905</a>
                </div>
                <div>
                  Email:
                  <a
                    target="_blank"
                    href="mailto:nhungnguyen@hodo.com.vn"
                  >nhungnguyen@hodo.com.vn</a>
                </div>
                <div>
                  Website:
                  <a href="https://www.hodo.com.vn" target="_blank">https://www.hodo.com.vn</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import appUtils from '../../utils/appUtils'
import { HODO_PACKAGE } from '../../utils/constants'

export default {
  name: 'ModalRedeem',
  props: {
    selectedPackage: {
      type: Object,
      default: () => ({})
    },
    isActive: Boolean
  },
  data () {
    return {
      isLoading: false,
      voucherCode: '',
      voucherInfo: {},
      HODO_PACKAGE,
      appUtils
    }
  },
  computed: {
    orgId () {
      const orgInfo = this.$user?.members?.[0]

      return (
        this.$globalClinic?.org_id || this.$globalOrg?.id || orgInfo?.org_id
      )
    },
    isPackagePro () {
      return this.selectedPackage?.id === HODO_PACKAGE.pro.value
    },
    remainingPrice () {
      if (this.voucherInfo?.DiscountType === 2) {
        const price =
          this.selectedPackage?.per_year_price -
          this.voucherInfo?.DiscountPercent *
            (this.selectedPackage?.per_year_price / 100)
        return Math.round(price * 100) / 100
      } else if (this.voucherInfo?.DiscountType === 1) {
        const price =
          this.selectedPackage?.per_year_price -
            this.voucherInfo?.DiscountMoney || 0
        return Math.round(price * 100) / 100
      } else if (this.voucherInfo?.DiscountType === 1) {
        const price =
          this.selectedPackage?.Price - this.voucherInfo?.DiscountMoney || 0
        return Math.round(price * 100) / 100
      }

      return this.selectedPackage?.per_year_price
    }
  },
  watch: {},
  created () {
    this.handleGetVoucherInfo()
  },
  methods: {
    handleShowModal (show) {
      window.$('#ModalRedeem').modal(show ? 'show' : 'hide')
    },
    async handleGetVoucherInfo () {
      try {
        this.isLoading = true
        const params = {
          //   voucher_code: this.voucherCode
          org_id: this.orgId
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getVoucherInfo(params)
        this.voucherInfo = response.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    hideModal () {
      this.voucherInfo = {}
      this.voucherCode = ''
    },
    handleGetDiscount () {
      if (this.voucherInfo?.DiscountType === 1) {
        return this.voucherInfo?.DiscountMoney
          ? appUtils.numberFormat(this.voucherInfo?.DiscountMoney, 'VNĐ')
          : ''
      } else if (this.voucherInfo?.DiscountType === 2) {
        return `${this.voucherInfo?.DiscountPercent || 0}%`
      }
    },
    async handleRedeem () {
      try {
        if (
          !this.orgId ||
          !this.selectedPackage.id ||
          !this.voucherInfo?.Code
        ) {
          return
        }

        const params = {
          group_id: this.selectedPackage.id,
          org_id: this.orgId,
          voucher_code: this.voucherInfo?.Code
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postRedeemPackage(params)

        if (response.data.code === 200024) {
          this.$toast.open({
            message: 'Voucher đã được sử dụng!',
            type: 'error'
          })
        } else {
          this.$toast.open({
            message: 'Sử dụng Voucher thành công!',
            type: 'success'
          })
          this.handleShowModal(false)
          this.$emit('onRefresh', true)
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: 'Có lỗi trong quá trình sử dụng Voucher!',
          type: 'warning'
        })
      }
    }
  }
}
</script>
  <style scoped lang="scss">
.cs-close {
  background-color: transparent;
  font-size: 30px;
  border: none;
  color: white;
}

.cs-bg-pro {
  background: #370fd9;
  background: linear-gradient(to right, #370fd9 0%, #e3563b 100%);
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  color: white;
}

.cs-standard {
  color: rgb(48, 96, 241);
}

.cs-plus {
  color: rgb(255, 199, 68);
}

.cs-bg-plus {
  background-color: rgb(255, 199, 68);
  color: white;
}
</style>
  