<template>
  <div>
    <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead class="d-none d-md-table">
            <tr>
              <th>Thông tin bác sĩ</th>
              <th>Chuyên khoa</th>
              <th>Chức vụ</th>
              <th>Địa chỉ</th>
            </tr>
          </thead>
          <tbody id="doctorListing">
            <tr v-for="(item, index) in listing.data" :key="index" class="table-tr mt-2">
              <td data-label="Thông tin bệnh nhân" class="table-td-name-avatar">
                <div class="d-flex py-1 align-items-center">
                  <div class="ml-2">
                    <span v-if="item.doctor && item.doctor.user.avatar" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(item.doctor.user.avatar)+')'"></span>
                    <span v-if="item.doctor && !item.doctor.user.avatar" class="avatar avatar-sm avatar-rounded bg-dark text-white">
                      {{ item.doctor && item.doctor.name ? item.doctor.name.charAt(0) : '' }}
                    </span>
                  </div>
                  <div class="flex-fill">
                    <p class="p-0 m-0">{{item.doctor ? item.doctor.name : ''}}</p>
                    <span class="robo-12-400 text-muted">{{item.doctor ? formatPhoneNumber(item.doctor.phone) : ''}}</span>
                  </div>
                </div>
              </td>
              <td data-label="trạng thái">
                <span class="robo-16-400">{{item.doctor && item.doctor.majors ? item.doctor.majors : ''}}</span>
              </td>
              <td class="table-td-action">
                <span class="robo-16-400">{{item.doctor && item.doctor.chuc_vu ? item.doctor.chuc_vu : ''}}</span>
              </td>
              <td class="table-td-action">
                <span class="robo-16-400">{{item.doctor && item.doctor.address ? item.doctor.address : ''}}</span>
              </td>
            </tr>
            <tr>
              <td colspan="5" v-if="listing && !listing.count && is_loaded">
                <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
                  <img src="../../../public/assets/images/icon/icon_circlenodoctor.svg" class="img-responsive p-0" alt="">
                  <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy bác sĩ nào' : 'Danh sách rỗng' }}</p>
                </div>
              </td>
            </tr>
            <tr v-for="i in 5" v-if="!is_loaded">
              <td>
                <div class="skeleton-avatar avatar-sm avatar-rounded"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!is_loaded">
            <tr v-for="i in 5" :key="i">
              <td>
                <div class="skeleton-avatar avatar-sm avatar-rounded"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'clinicDoctorListing',
    data () {
      return {
        search: '',
        is_loaded: true,
        listing: [],
        clinic_id: '',
        params: {
          limit: 20,
          statuses: [1],
          search: ''
        },
        doctor_master_id: 0
      }
    },
    mounted () {
      let self = this
      self.clinic_id = this.$clinicId ? this.$clinicId : ''
      self.getClinicDoctor()
      self.doctor_master_id = this.$globalClinic && this.$globalClinic.doctor_id ? this.$globalClinic.doctor_id : ''
      self.doctor_self = this.$globalClinic && this.$globalClinic.pivot && this.$globalClinic.pivot.doctor_id ? this.$globalClinic.pivot.doctor_id : ''
      let action = document.querySelector('#doctorListing')
      action.addEventListener('scroll', e => {
        if (Math.ceil(action.scrollTop + action.clientHeight) >= action.scrollHeight) {
          if (self.listing.count < self.listing.total) {
            self.getClinicDoctor(self.listing.count + 10)
          }
        }
      })
    },
    methods: {
      async getClinicDoctor (num) {
        try {
          let self = this
          self.is_loaded = false
          self.params.statuses = [1]
          self.params.limit = num || 20
          // self.params.search = self.change_search ? self.change_search : ''
          const result = await self.$rf.getRequest('DoctorRequest').getDoctorWorkspace(self.clinic_id, self.params)
          self.listing = result.data
          self.listing.data = self.listing.data.filter(item => item.doctor_id !== self.doctor_self)
          // self.listing.count = self.listing.data.count
        } catch (e) {
          console.log(e)
        } finally {
          this.is_loaded = true
        }
      },
      getImageURL (path) {
        return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
      },
      formatWeekdayTime (date_time) {
        let curr_week = window.moment().week()
        let date_time_week = window.moment(date_time).week()
        if (curr_week === date_time_week) {
          return window.moment(date_time).fromNow()
        } else {
          return window.moment(date_time).format('DD/MM/YYYY')
        }
      },
      formatPhoneNumber (number) {
        // Filter only numbers from the input
        let cleaned = ('' + number).replace(/\D/g, '')
        // Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return match ? match[1] + ' - ' + match[2] + ' - ' + match[3] : ''
        };
        return null
      }
    }
  }
</script>
<style scoped>
</style>