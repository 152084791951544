<template>
	<div class="row">
		<div class="col-sm-10 offset-sm-1">
			<div class="row">
				<div class="col-sm-6 mt-2">
					<div class="form-group">
						<label class="robo-16-500 required" for="txt_place">{{$t('personal_settings.lbl_work_place')}}</label>
						<input type="text" v-model="params.noi_cong_tac" class="form-control" id="txt_place" :placeholder="$t('personal_settings.lbl_ex_dhy')" autocomplete="off">
						<small class="form-text text-warning">{{error.err_noi_cong_tac}}</small>
					</div>
				</div>
				<div class="col-sm-6 mt-2">
					<div class="form-group">
						<label class="robo-16-500 required" for="txt-position">{{$t('personal_settings.lbl_position')}}</label>
						<input type="text" v-model="params.chuc_vu" class="form-control" id="txt-position" :placeholder="$t('personal_settings.lbl_ex_manager')" autocomplete="off">
						<small class="form-text text-warning">{{error.err_chuc_vu}}</small>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6">
					<div class="row">
						<div class="col-sm-6 mt-2">
							<div class="form-group">
								<label class="robo-16-500 required" for="txt-start-time">{{$t('personal_settings.lbl_start_time')}}</label>
								<select class="form-control" id="txt-start-time" v-model="params.start_year">
									<option value="">{{$t('personal_settings.lbl_choose_time')}}</option>
									<option :value="year_now - n" v-for="n in 120" :key="n">{{year_now - n}}</option>
								</select>
							</div>
						</div>
						<div class="col-sm-6 mt-2">
							<div class="form-group">
								<label class="robo-16-500" for="txt-end-time">{{$t('personal_settings.lbl_end_time')}}</label>
								<select class="form-control" id="txt-end-time" v-model="params.end_year">
									<option value="">{{$t('personal_settings.lbl_choose_time')}}</option>
									<option :value="year_now - n" v-for="n in 120" :key="n">{{year_now - n}}</option>
								</select>
							</div>
						</div>
						<div class="col-12">
							<small class="form-text text-warning">{{error.err_year}}</small>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-2">
				<div class="robo-12-400 text--red">* {{$t('personal_settings.lbl_msg_work')}}</div>
			</div>
			<div class="d-flex">
				<button class="mt-2 btn btn-secondary" @click="onBack()">{{$t('personal_settings.btn_back')}}</button>
				<button class="ml-2 mt-2 btn bg-pri bd-pri text-white" :disabled="!loaded" @click.prevent="createExp()"><span class="loader mr-2 text-white" style="width: 18px; height: 18px;" v-if="!loaded"></span> {{is_edit ? this.$t('personal_settings.btn_update'):this.$t('personal_settings.btn_add')}}</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'WorkExperience',
  props: ['is_edit', 'exp'],
  data () {
    return {
      loaded: true,
      year_now: 0,
      params: {
        noi_cong_tac: '',
        chuc_vu: '',
        start_year: '',
        end_year: ''
      },
      error: {
        err_noi_cong_tac: '',
        err_chuc_vu: '',
        err_year: ''
      }
    }
  },
  mounted () {
    let self = this
    const time = new Date()
    self.year_now = time.getFullYear() + 1
    if (self.is_edit) {
      self.getExp()
    }
  },
  methods: {
    onBack () {
      let self = this
      if (self.is_edit) {
        self.$emit('onBackExEdit', false)
      } else {
        self.$emit('onBackEx', false)
      }
    },
    checkValidate () {
      let self = this
      if (!self.params.noi_cong_tac) {
        self.error.err_noi_cong_tac = 'Nơi công tác không được bỏ trống'
        return false
      } else if (!self.params.chuc_vu) {
        self.error.err_chuc_vu = ''
        self.error.err_chuc_vu = 'Nơi công tác không được bỏ trống'
        return false
      } else if (!self.params.start_year) {
        self.error.err_year = ''
        self.error.err_year = 'Thời gian bắt đầu không được bỏ trống'
        return false
      } else if (self.params.end_year && self.params.start_year > self.params.end_year) {
        self.error.err_year = 'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc'
        return false
      } else {
        self.error.err_noi_cong_tac = ''
        self.error.err_chuc_vu = ''
        self.error.err_year = ''
        return true
      }
    },
    getExp () {
      try {
        let self = this
        self.params.noi_cong_tac = self.exp.noi_cong_tac
        self.params.chuc_vu = self.exp.chuc_vu
        self.params.start_year = self.exp.start_year
        if (self.exp.end_year) {
          self.params.end_year = self.exp.end_year
        }
      } catch (e) {
        console.log(e)
      }
    },
    createExp () {
      let self = this
      try {
        self.loaded = false
        // alert(JSON.stringify(self.params))
        if (self.checkValidate()) {
          if (self.is_edit) {
            self.params.id = self.exp.id
          }
          self.$emit('onSubmitExps', self.params, self.is_edit)
        }
      } catch (e) {
        console.log(e)
      } finally {
        self.loaded = true
      }
    }
  }
}
</script>
<style scoped></style>