<template>
    <div class="">
      <div class="card radius-10 border-0">
        <div class="bound-border">
          <div class="p-10-10">
           <div class="d-flex justify-content-center" style="padding: 51px 0 152px 0;">
            <div class="col-md-8">
              <div>
                <DoctorInfo></DoctorInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import DoctorInfo from '../../components/DoctorInfo.vue'
  // import ChangePassword from '../../components/Doctor/ChangePassword.vue'
  export default {
    name: 'DoctorSettingInfo',
    // components: {DoctorInfo, ChangePassword},
    components: {DoctorInfo},
    data () {
      return {
        loading: false
      }
    },
    mounted () {
      // this.type = this.$route.query.it ? this.$route.query.it.toLowerCase() : 1
      // if (!this.$route.query.it) {
      //   this.changeType(1)
      // }
    },
    methods: {
      // changeType (type) {
      //   this.type = type
      //   let query = this.$route.query
      //   let newType = type
      //   this.$router.push({name: 'DoctorSetting', query: {...query, it: newType}})
      // }
    }
  }
</script>
<style scoped>
  .card-item {
    width: 100%; 
    height: 60px; 
    border-radius: 10px; 
    padding: 0px 16px 0 16px; 
  }
  .bg-none-action{
    background-color: #F2F5FA; 
    cursor: pointer;
  }
  .bg-action {
    background-color: #E5EEFE;
    cursor: default;
  }
  .tab-text {
    font-size: 20px;
    font-weight: 500;
  }
  @media only screen and (max-width: 610px) {
  .tab-text {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
