<template>
  <div>
      <label class="form-label required d-flex align-items-center"
        ><img
          src="../../../public/assets/images/icon/select-doctor.svg"
          width="24"
          alt=""
        /><span class="ml-1 txt-pri robo-16-500">Chọn bác sĩ</span></label
      >
      <v-select
        class="select-box"
        :options="(doctors && doctors.data) || []"
        v-model="selected_doctor"
        label="name"
        placeholder="Chọn bác sĩ"
        @search="searchDoctor"
        :filterable="false"
        @input="selectedDoctor"
        :disabled="disabled"
      >
        <div slot="no-options">
          <span v-if="!loading"
            >Không tìm thấy bác sĩ nào!</span
          >
        </div>
        <template #list-footer>
          <li class="text-center mt-1">
            <span
              class="text-secondary spinner-border spinner-border-sm mr-2"
              role="status"
              v-if="loading"
            ></span>
          </li>
        </template>
      </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'SelectDoctorCpn',
  props: ['filter', 'disabled', 'reload'],
  components: {vSelect},
  data () {
    return {
      doctors: null,
      loading: false,
      selected_doctor: null
    }
  },
  watch: {
    filter () {
      this.getDoctors()
    }
  },
  mounted () {
    this.getDoctors()
  },
  methods: {

    // Doctor
    async getDoctors (kw) {
      this.loading = true
      let params = {
        limit: 20
        // cr_id: this.selected_consulting?.id,
        // person_diseases_id: this.selected_disease?.id
      }
      if (kw) {
        params.search = kw
      }
      if (this.filter)params = {...params, ...this.filter}
      try {
        let resp = await this.$rf.getRequest('DoctorRequest').getAllDoctors(params)
        this.doctors = resp.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    searchDoctor (search) {
      this.err_doctor = null
      this.getDoctors(search)
    },
    selectedDoctor (d) {
      this.$emit('selectedDoctor', d)
    }
  }
}
</script>

<style scoped>
</style>