<template>
  <div>
    <div class="table-responsive">
      <table v-if="doctors" class="table table-vcenter table-mobile-md card-table table-scroll">
        <thead>
        <tr>
          <th class="pl-2">{{ $t("title.doctor.name") }}</th>
          <th>{{ $t("title.user_id") }}</th>
          <th>{{ $t("title.contact") }}</th>
          <th v-if="isAdmin">Last Login</th>
          <!-- <th v-if="isAdmin">Yêu cầu pending</th>
          <th v-if="isAdmin">Yêu cầu</th> -->
          <th></th>
        </tr>
        </thead>
        <tbody class="h-100" style="background-color: white;">
        <tr v-for="doctor in doctors.data" :key="doctor.id" class="border-bottom">
          <td :data-label="$t('title.doctor.name')" class="pl-2">
            <div class="d-flex lh-sm py-1 align-items-center">
                <span v-if="doctor.user && doctor.user.avatar" class="avatar mr-2" :style="'background-image: url(' + getImageURL(doctor.user.avatar) + ')'"></span>
              <span v-if="doctor.user && !doctor.user.avatar" class="avatar mr-2">{{ doctor.user.name ? doctor.user.name.charAt(0) : '' }}</span>
              <div class="flex-fill">
                <div class="strong">{{ doctor.user ? doctor.user.name : '' }}</div>
                <small class="text-muted">ID:   <a v-if="isAdmin" href="javascript:;"  @click="onLoginDoctor(doctor.id)">
                  {{ doctor.id }}
              </a> - <a href="javascript:;" class="text-reset">{{ doctor.user ? doctor.user.username : '' }}</a></small>
              </div>
            </div>
          </td>
          <td :data-label="$t('title.user_id')">
            <div>{{ doctor.user_id }}</div>
          </td>
          <td :data-label="$t('title.contact')">
            <div>{{ doctor.phone }}</div>
            <div class="text-muted text-h5">{{ doctor.address }}</div>
            <div v-if="isAdmin" class="text-muted text-h5"><strong>Person: </strong>{{ doctor.person_count }}</div>
          </td>
         <!--  <td data-label="Yêu cầu pending" v-if="isAdmin" >
            <div></div>
          </td>
          <td data-label="yêu cầu" v-if="isAdmin" >
            <div></div>
          </td> -->
          <td data-label="Last Login" v-if="isAdmin" >
            <div>{{doctor && doctor.user && doctor.user.last_login && fontmatDateDDMMYYYY(doctor.user.last_login) }}</div>
          </td>
          <td class="text-center" style="overflow: hidden;">
            <div class="row" v-if="isAdmin">
              <a href="javascript:;" class="btn col-sm-12 m-1" @click="onResetPassword(doctor.user_id)">
                Reset Password
              </a>
              <div class="row">
                <div class="col">
                  <a href="javascript:;" class="btn col-sm-12 mr-1 mt-1 bg--green-corl" @click="onWelcome(doctor)">
                Welcome
              </a>
                </div>
                <div class="col">
                  <input class="form-control mr-sm-2 bg-white" type="number" placeholder="Clinic ID" aria-label="Clinic ID" v-model="doctor.clinicId">
                </div>
              </div>

              <a href="javascript:;" :class="doctor.status == 0 ? 'text--yellow bg--yellow-corl' : doctor.status == 1 ? 'text-red bg--red-corl' : ''" class="btn col-sm-12 m-1"  @click="onActive(doctor.id, doctor.status)">
                {{doctor.status == 0 ? 'Active' : doctor.status == 1 ? 'DeActive' : ''}}
              </a> 
            </div>
            <router-link v-if="isDoctor" class="m-1 icon-action-table h2" :to="'/doctors/' + doctor.id + '/edit'">
              <button class="btn btn-sm btn--bg-success btn--person">
                <div class="btn--edit"></div>
              </button>
            </router-link>
            <a title="xóa" v-if="isDoctor" href="javascript:;" class="m-1 icon-action-table h2" @click="onRemoveDoctor(doctor.id)">
              <button class="btn btn-sm btn--bg-danger btn--person">
                <div class="btn--delete"></div>
              </button>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import appUtils from '../utils/appUtils'

  export default {
    name: 'DoctorTable',
    props: {
      doctors: Object,
      isDoctor: Boolean,
      isAdmin: Boolean
    },
    methods: {
      onRemoveDoctor (id) {
        this.$emit('onRemoveDoctor', id)
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      onResetPassword (id) {
        this.$emit('onResetPassword', id)
      },
      onWelcome (doctor) {
        this.$emit('onWelcome', doctor)
      },
      onActive (id, status) {
        var r = confirm('Bạn có chắc chắn thực hiện thao tác này?')
        if (r) {
          this.$emit('onActive', id, status)
        }
      },
      onLoginDoctor (id) {
        this.$emit('onLoginDoctor', id)
      },
      fontmatDateDDMMYYYY (date_time) {
        return window.moment(date_time).format('DD/MM/YYYY HH:mm ')
      }
    }
  }
</script>
<style scoped>
</style>
