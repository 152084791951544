<template>
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-12">
        <a href="javascript:"  @click="goToday"  class="card text-white mb-3 card-clinic-dashboard" style="background-color: #D7F9EF">
          <div class="card-header card-header-clinic-dashboard"><i class="fa fa-calendar"></i></div>
          <div class="card-body">
            <h5 class="card-title">{{ stats.appt_today }}</h5>
            <p class="card-text text-dark">{{ $t("sidebar.manage.schedule_today") }}</p>
          </div>
        </a>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12">
        <a href="javascript:"  @click="goToWeek" class="card text-white mb-3 card-clinic-dashboard" style="background-color: #F4E1F0">
          <div class="card-header card-header-clinic-dashboard"><i class="fa fa-calendar"></i></div>
          <div class="card-body">
            <h5 class="card-title">{{ stats.appt_7_days }}</h5>
            <p class="card-text text-dark">{{ $t("sidebar.manage.schedule_week") }}</p>
          </div>
        </a>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12">
        <div class="card text-white mb-3 card-clinic-dashboard" style="background-color: #E1E9FF">
          <div class="card-header card-header-clinic-dashboard"><i class="fa fa-user"></i></div>
          <div class="card-body">
            <h5 class="card-title">{{ stats.person_new }}</h5>
            <p class="card-text text-dark">{{ $t("title.new_person") }}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'DoctorStatsAppointment',
    props: ['is_home'],
    data () {
      return {
        stats: {
          appt_7_days: 0,
          appt_today: 0,
          person_new: 0,
          person_total: 0
        }
      }
    },
    mounted () {
      let self = this
      self.getStat()
    },
    methods: {
      async getStat () {
        let self = this
        let resp = await self.$rf.getRequest('DoctorRequest').getDoctorStats()
        const {data} = resp
        self.stats = data
      },
      goToday () {
        let self = this

        return self.$router.push({path: '/doctor/appointments-today'})
      },
      goToWeek () {
        let self = this

        return self.$router.push({path: '/doctor/appointments-week'})
      }
    }
  }
</script>
