<template>
  <div class="row">
    <div class="col-sm-10 offset-sm-1">
      <div class="row">
        <div class="col-sm-6 mt-2">
          <div class="form-group">
            <label class="robo-16-500" for="txt-career">{{$t('personal_settings.lbl_practice_place')}}</label>
            <input
              type="text"
              class="form-control mt-1"
              v-model="params.noi_tu_nghiep"
              id="txt-career"
              :placeholder= "$t('personal_settings.lbl_ex_dhy')"
              autocomplete="off"
            />
            <small class="form-text text-warning">{{
              error.err_noi_tu_nghiep
            }}</small>
          </div>
        </div>
        <div class="col-sm-6 mt-2">
          <div class="form-group">
            <label class="robo-16-500" for="txt-specialized"
              >{{$t('personal_settings.lbl_specialized')}}</label
            >
            <input
              type="text"
              class="form-control mt-1"
              v-model="params.chuyen_nganh"
              id="txt-specialized" 
              autocomplete="off"
            />
            <small class="form-text text-warning">{{
              error.err_chuyen_nganh
            }}</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-6 mt-2">
              <div class="form-group">
                <label class="robo-16-500" for="txt-start-time"
                  >{{$t('personal_settings.lbl_start_time')}}</label
                >
                <select
                  class="form-control mt-1"
                  id="txt-start-time"
                  v-model="params.start_year"
                >
                  <option value="">{{$t('personal_settings.lbl_choose_time')}}</option>
                  <option :value="year_now - n" v-for="n in 120" :key="n">
                    {{ year_now - n }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 mt-2">
              <div class="form-group">
                <label class="robo-16-500" for="txt-end-time"
                  >{{$t('personal_settings.lbl_end_time')}}</label
                >
                <select
                  class="form-control mt-1"
                  id="txt-end-time"
                  v-model="params.end_year"
                >
                  <option value="">{{$t('personal_settings.lbl_choose_time')}}</option>
                  <option :value="year_now - n" v-for="n in 120" :key="n">
                    {{ year_now - n }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <small class="form-text text-warning">{{ error.err_year }}</small>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="robo-12-400 text--red">
          * {{$t('personal_settings.lbl_msg_education')}}
        </div>
      </div>
      <div class="d-flex">
        <button class="mt-2 btn btn-secondary" @click="onBack()">
          {{$t('personal_settings.btn_back')}}
        </button>
        <button
          class="ml-2 mt-2 btn bg-pri bd-pri text-white"
          @click.prevent="createProcess()"
        >
          {{ is_edit ? $t('personal_settings.btn_update') : $t('personal_settings.btn_add') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DoctorProcess',
  props: ['is_edit', 'process'],
  data () {
    return {
      load: true,
      params: {
        noi_tu_nghiep: '',
        chuyen_nganh: '',
        start_year: '',
        end_year: ''
      },
      error: {
        err_noi_tu_nghiep: '',
        err_chuyen_nganh: '',
        err_year: ''
      },
      year_now: 0
    }
  },
  mounted () {
    let self = this
    const day = new Date()
    self.year_now = day.getFullYear() + 1
    if (self.is_edit) {
      self.getProcess()
    }
  },
  methods: {
    onBack () {
      let self = this
      if (self.is_edit) {
        self.$emit('onBackProcessEdit', false)
      } else {
        self.$emit('onBackProcess', false)
      }
    },
    checkValidate () {
      let self = this
      if (!self.params.noi_tu_nghiep) {
        self.error.err_noi_tu_nghiep = 'Nơi tu nghiệp không được bỏ trống'
        return false
      } else if (!self.params.chuyen_nganh) {
        self.error.err_noi_tu_nghiep = ''
        self.error.err_chuyen_nganh = 'Chuyên ngành không được bỏ trống'
        return false
      } else if (!self.params.start_year) {
        self.error.err_chuc_vu = ''
        self.error.err_year = 'Thời gian bắt đầu không được bỏ trống'
        return false
      } else if (
        self.params.end_year &&
        self.params.start_year > self.params.end_year
      ) {
        self.error.err_year =
          'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc'
        return false
      } else {
        self.error.err_noi_cong_tac = ''
        self.error.err_chuc_vu = ''
        self.error.err_year = ''
        return true
      }
    },
    getProcess () {
      try {
        let self = this
        self.params.noi_tu_nghiep = self.process.noi_tu_nghiep
        self.params.chuyen_nganh = self.process.chuyen_nganh
        self.params.start_year = self.process.start_year
        if (self.process.end_year) {
          self.params.end_year = self.process.end_year
        }
      } catch (e) {
        console.log(e)
      }
    },
    createProcess () {
      let self = this
      try {
        self.loaded = false
        if (self.checkValidate()) {
          if (self.is_edit) {
            self.params.id = self.process.id
          }
          self.$emit('onSubmitProcess', self.params, self.is_edit)
        }
      } catch (e) {
        console.log(e)
      } finally {
        self.loaded = true
      }
    }
  }
}
</script>
<style scoped></style>